/*pedigree report html iframe css*/
table {
    width: 100%;
}
p.horse {
    font-size: 2em;
    margin: 0;
    text-align: center;
}
p {
    line-height: 110%;
    margin-top: 0;
    margin-bottom: 0;
}
td {
    line-height: 110%;
    margin-top: 0;
    margin-bottom: 0;
}
.remHRC {
    margin-left: 9pt;
    text-indent: -9pt;
    text-align: left;
}
.remHRR {
    margin-left: 9pt;
    text-indent: -9pt;
    text-align: left;
    color: maroon;
    font: bold;
}
.remHRE {
    margin-left: 9pt;
    text-indent: -9pt;
    text-align: right;
}

.rem0 {
    margin-left: 20pt;
    text-indent: -20pt;
    text-align: justify;
}
.sireref {
    text-align: justify;
    line-height: 100%;
    font-weight: bold;
}
.sirerefinglis {
    text-align: justify;
    line-height: 100%;
    font-weight: bold;
    font-style: italic;
}
.header {
    text-align: justify;
    line-height: 100%;
    font-weight: bold;
}
.ss {
    margin-left: 20pt;
    text-indent: -20pt;
    text-align: justify;
}
.rem1 {
    margin-left: 27pt;
    text-indent: -18pt;
    text-align: justify;
}
.pr {
    margin-left: 36pt;
    text-indent: -27pt;
    text-align: justify;
}
.ds {
    text-align: justify;
}
.br {
    margin-left: 27pt;
    text-indent: -18pt;
    text-align: justify;
}
.rem2 {
    margin-left: 36pt;
    text-indent: -18pt;
    text-align: justify;
}
.rem3 {
    margin-left: 45pt;
    text-indent: -18pt;
    text-align: justify;
}
.rem4 {
    margin-left: 54pt;
    text-indent: -18pt;
    text-align: justify;
}
.rem5 {
    margin-left: 63pt;
    text-indent: -18pt;
    text-align: justify;
}
.rem6 {
    margin-left: 72pt;
    text-indent: -18pt;
    text-align: justify;
}
.rem7 {
    margin-left: 81pt;
    text-indent: -18pt;
    text-align: justify;
}
.rem8 {
    margin-left: 90pt;
    text-indent: -18pt;
    text-align: justify;
}
.rem9 {
    margin-left: 99pt;
    text-indent: -18pt;
    text-align: justify;
}
.rem10 {
    margin-left: 108pt;
    text-indent: -18pt;
    text-align: justify;
}
.rem11 {
    margin-left: 117pt;
    text-indent: -18pt;
    text-align: justify;
}
.rem12 {
    margin-left: 126pt;
    text-indent: -18pt;
    text-align: justify;
}
.remA {
    margin-left: 50pt;
    text-indent: -50pt;
    text-align: justify;
}
.brem1 {
    margin-left: 27pt;
    text-indent: -18pt;
    text-align: justify;
}
.brem2 {
    margin-left: 36pt;
    text-indent: -18pt;
    text-align: justify;
}
.brem3 {
    margin-left: 45pt;
    text-indent: -18pt;
    text-align: justify;
}
.brem4 {
    margin-left: 54pt;
    text-indent: -18pt;
    text-align: justify;
}

.rrTable {
    margin: 3px 0 13px;
}
.rpTable {
    margin: 3px 0 13px;
}

.tright {
    text-align: right;
}
.tleft {
    text-align: left;
}
.tcenter {
    text-align: center;
}
.turight {
    text-align: right;
    border-bottom-style: solid;
    border-bottom-width: thin;
}

.talt {
    background: #ebebeb;
}

.inbreedingNC {
    background: #ccffff;
    font-weight: bold;
}
.inbreedingNF {
    background: #ffe4e1;
    font-weight: bold;
}
.inbreedingYC {
    background: #ccffff;
    font-weight: bold;
}
.inbreedingYF {
    background: #ffe4e1;
    font-weight: bold;
}
.inbreedingNCH {
    background: #ffffff;
    font-weight: normal;
}
.inbreedingNFH {
    background: #ebebeb;
    font-weight: normal;
}

div#links a span {
    display: none;
}
div#links a:hover span {
    display: block;
    position: fixed;
    top: 200px;
    left: 450px;
    width: 250px;
    padding: 5px;
    margin: 10px;
    z-index: 100;
    color: black;
    background: yellow;
    font: 10px Verdana, sans-serif;
    text-align: center;
    margin-left: 0pt;
    text-indent: 0pt;
    text-align: justify;
}

div#links p {
    margin-left: 27pt;
    text-indent: -18pt;
    text-align: justify;
}

img {
    border: 0;
}
.minisubtitle {
    color: #000000;
    font: 12px verdana, arial, sans-serif;
    font-weight: bold;
}

.pedigreestyle {
    padding: 10px 30px;
    font: 70% Verdana, arial, sans-serif;
    margin: 0;
    color: #222;
}
